import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import CustomButton from '../../theme/style-components/CustomButton';
import CustomInput from '../../theme/style-components/CustomInput';
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserType } from '../../store/auth/authSlice';
import { useDeletePackageMutation, useGetAddressBookQuery, useGetMyOrderQuery, useGetOrderQuery, useLazyGetMyOrderQuery, useLazyGetOrderCSVTemplateQuery, usePostPackageCSVMutation } from '../../store/orders/orderApiSlice';
import { useLazyGetQuoteQuery, usePayOtherFeesMutation } from '../../store/payments/paymentsApiSlice';
import { toast } from 'react-toastify';
import { deletePackages, setPackages } from '../../store/orders/orderSlice';
import CustomModal from '../../theme/style-components/CustomModal';
import CreatePackage from '../packages/CreatePackage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOtherFees from './AddOtherFees';
import { showError } from '../../shared/helperFunction';
import swal from "sweetalert";
import ErrorPopup from '../packages/ErrorPopup';

const OrderPackages = () => {

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];


    //route parameters
    let { orderID } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    //redux logic
    const packages = useSelector(state => state.orders.packages);

    const dispatch = useDispatch();
    const userType = useSelector(selectCurrentUserType);
    //state variables
    const [showAddPackageDialog, setShowAddPackageDialog] = useState(false);
    const [variableOrderID, setVariableOrderID] = useState(0);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isPackageEditing, setIsPackageEditing] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [quoteData, setQuoteData] = useState({})
    const [showPayButton, setShowPayButton] = useState(false);
    const [isOtherFeesOpen, setIsOtherFeesOpen] = useState(false);
    const [addressBooksData, setAddressBooksData] = useState([]);
    const [errorMessageFred, setErrorMessageFred] = useState('');
    const [isErrorFredModalOpen, setIsErrorFredModalOpen] = useState(false);

    const {
        data: addressBooks,
        isLoading: isGetAddressBookLoading,
        isSuccess: isGetAddressBookSuccess,
        isError: isGetAddressBookError
    } = useGetAddressBookQuery();

    useEffect(() => {
        if (addressBooks?.data?.length > 0) {
            setAddressBooksData(addressBooks?.data)
        }
    }, [addressBooks])

    //base query hooks
    const [getQuote, {
        isLoading: isQuoteLoading, data: quote, isSuccess: isQuoteSuccess, isError, error
    }] = useLazyGetQuoteQuery();

    const {
        isLoading: isOrderLoading, data: orders, isSuccess: isOrderSuccess, isError: isOrderError, error: orderError, refetch: refetchOrder
    } = useGetOrderQuery(orderID);

    const {
        isLoading: isMyOrderLoading,
        data: myOrderReturned,
        isSuccess: isMyOrderSuccess,
        isError: isMyOrderError,
        error: myOrderError, refetch: refetchMyOrder,
    } = useGetMyOrderQuery({ page: 1, query: `&order_id=${orderID}` });


    useEffect(() => {
        if (quoteData) {
            if (userType === "ADMIN") {
                refetchOrder()
            } else {
                refetchMyOrder()
            };
        };
    }, [quote, quoteData, userType])

    useEffect(() => {
        if (packages?.length) {
            setVariableOrderID(0)
        }
    }, [packages])

    useEffect(() => {
        if (userType === "MERCHANT") {
            if (!isMyOrderLoading && !isMyOrderError && myOrderReturned.data[0]?.packages) {
                dispatch(setPackages(myOrderReturned.data[0]?.packages));
                return;
            }
        }
        if (userType === "ADMIN" && orders?.data[0]?.packages) {
            dispatch(setPackages([...orders?.data[0]?.packages]));
        }

        if (userType === "ADMIN") {
            if (!isOrderLoading && orders?.data[0]?.packages) {
                dispatch(setPackages(orders?.data[0]?.packages));
                return;
            }
        }
    }, [isOrderLoading, orders, isMyOrderLoading, myOrderReturned, isMyOrderError, userType]);


    //methods
    const handleGetQuote = async () => {
        const quoteResponse = await getQuote({ order: orderID }).unwrap().then((res) => {
            if (res) {
                setQuoteData(res)
                setShowPayButton(true);
            }
        }).catch((err) => {
            if (err) {
                setShowPayButton(false);
                if (orders?.data?.[0]?.subscription?.name === "Pilot_Fred") {
                    if (err?.data?.message === "out of subscription boundary") {
                        setIsErrorFredModalOpen(true);
                        setErrorMessageFred("This address is over the 10km maximum allowance for the Pilot. Please email support@brang.com.au for a quote and delivery availability.");
                    } else {
                        setErrorMessageFred(err?.data?.message);
                        setIsErrorFredModalOpen(true);
                    }
                }
                else {
                    showError(err?.data);
                }
            }
        })
    };

    const handlePaymentClicked = () => {
        navigate(`/orders/packages/pay/${orderID}`);
    };

    const [postPackageCSV] = usePostPackageCSVMutation();
    const [deletePackage] = useDeletePackageMutation();
    const [getMyOrder] = useLazyGetMyOrderQuery();
    const [getOrderCSVTemplate, { isFetching: isCsvLoading }] = useLazyGetOrderCSVTemplateQuery();


    const handleImportCSV = async (e) => {
        if (e.target.files && e.target.files[0]) {
            let payload = new FormData();
            payload.append("order_id", orderID);
            payload.append("csv_packages", e.target.files[0]);
            try {
                const result = await postPackageCSV(payload).unwrap().then((res) => {
                    if (res) getMyOrder({ page: 1, query: `&order_id=${orderID}` }).unwrap().then((res) => {
                        refetchOrder();
                        if (res && res?.data[0]?.packages) dispatch(setPackages([...res?.data[0]?.packages]));
                    })
                });
                toast.success("Packages added!!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } catch (error) {
                toast.error(`Something went wrong!!`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    };

    const callDeletePackage = async (id) => {
        const response = await deletePackage({ package_id: id }).unwrap().then((res) => {
            if (res) {
                dispatch(deletePackages(id));
                setShowPayButton(false);
                setQuoteData({});
            }
            res && toast.success("Packages deleted!!", {
                position: toast.POSITION.TOP_RIGHT
            });
            setMenuAnchor(null);
            setIsMenuOpen(false);
        }).catch((err) => {
            err && toast.error(err.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }

    const TotalAmountWithoutGST = packages?.length > 0 && packages?.reduce((acc, curr) => acc + curr?.quote_distribution?.amount, 0);

    const handleDownloadCSVTemplate = async () => {
        try {
            const templateData = getOrderCSVTemplate().unwrap().then((res) => {
                const blob = new Blob([res], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "order-template.csv";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            }).catch((err) => {
                err && toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        } catch (err) {
            err && toast.error("Something went wrong", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const [payOtherFees, { isLoading: payOtherFeesLoading }] = usePayOtherFeesMutation();

    const handleAddOtherFees = async (formValue) => {
        let response = await payOtherFees({ order_id: orderID, other_fees_cents: formValue?.otherFees * 100, comment: formValue?.otherFeesComment }).unwrap().then((res) => {
            if (res) {
                toast.success(`${res.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setIsOtherFeesOpen(false);
                setQuoteData({})
            }
        }).catch((err) => {
            if (err) {
                let errorMessage;
                for (const key in err.data.errors) {
                    if (Array.isArray(err.data.errors[key]) && err.data.errors[key].length > 0) {
                        errorMessage = err.data.errors[key][0];
                        break;
                    }
                }
                if (errorMessage) {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        });

    };

    const orderStatus = orders?.data[0]?.order_status
    const fred_pilot_discount = ((quoteData?.data?.distribution[0]?.total_charge_amount) / 100).toFixed(2);
    const subscription_type = orders?.data[0]?.subscription?.name ?? "NA"
    // const final_total = subscription_type === "Pilot_Fred" ? (((quoteData?.data?.distribution[0]?.total_charge_amount) / 100).toFixed(2) - fred_pilot_discount) : ((quoteData?.data?.distribution[0]?.total_charge_amount) / 100).toFixed(2)
    const is_fred_pilot = subscription_type === "Pilot_Fred"

    const final_total = is_fred_pilot
        ? (parseFloat((quoteData?.data?.distribution[0]?.total_charge_amount) / 100) - fred_pilot_discount).toFixed(2)
        : (parseFloat((quoteData?.data?.distribution[0]?.total_charge_amount) / 100)).toFixed(2);
    const inputCSVRef = useRef(null);

    const handleErrorFredPopupClose = () => {
        setIsErrorFredModalOpen(false);
        setErrorMessageFred('')
    }

    return (
        <>
            <CustomPaperBox>
                <Grid padding="15px 15px" container spacing={2}>
                    <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Typography sx={{
                            fontSize: '18px',
                            margin: "15px 0",
                            color: '#696cff',
                            fontWeight: '500'

                        }}>Delivery items:</Typography>
                    </Grid>
                    <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Box sx={{ textAlign: "end" }}>
                            <CustomButton color="primary" onClick={() => {
                                setIsPackageEditing(false);
                                setShowAddPackageDialog((prevState) => !prevState);
                            }} buttonName="Add Delivery" />
                        </Box>
                    </Grid>

                    <TableContainer sx={{
                        backgroundColor: 'transparent'
                    }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{
                                    '& th': {
                                        color: '#dbdbebde',
                                        borderBottomColor: "#dbdbeb1f",
                                        backgroundColor: "#47475e45"
                                    }
                                }}>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Mobile Number</TableCell>
                                    <TableCell align="center">Address</TableCell>
                                    <TableCell align="center">Package Weight</TableCell>
                                    {!is_fred_pilot && <TableCell align="center">Amount</TableCell>}
                                    <TableCell align="center">Distance</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {packages.length !== 0 ? packages.map((pack, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& td,th': {
                                                color: '#dbdbeb99',
                                                borderBottomColor: "#dbdbeb1f",
                                            }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {pack.id}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {pack.contact_name}
                                        </TableCell>
                                        <TableCell align="center">{pack.contact_email}</TableCell>
                                        <TableCell align="center">{pack.contact_phone}</TableCell>
                                        <TableCell align="center">{pack.drop_address && pack.drop_address.address}</TableCell>
                                        <TableCell align="center">
                                            {pack.dimension === 1 && "upto 1kg"}
                                            {pack.dimension === 2 && "upto 5kg"}
                                            {pack.dimension === 3 && "upto 10kg"}
                                        </TableCell>
                                        {!is_fred_pilot && pack.quote_distribution && <TableCell align="center"> {`AUD  ${(pack.quote_distribution.amount / 100).toFixed(2)}`}</TableCell>}
                                        <TableCell align="center">{pack.quote_distribution && `${(pack.quote_distribution.distance / 1000).toFixed(2)} Km`}</TableCell>
                                        <TableCell align="center">
                                            <Box sx={{ gap: "10px", display: "flex" }}>
                                                <EditIcon cursor="pointer"
                                                    onClick={() => {
                                                        setSelectedPackage(pack)
                                                        setIsPackageEditing(true);
                                                        setShowAddPackageDialog(prevState => !prevState);
                                                    }} />
                                                <DeleteIcon cursor="pointer"
                                                    onClick={() => callDeletePackage(pack?.id)} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '& td,th': {
                                        color: '#dbdbeb99',
                                        borderBottomColor: "#dbdbeb1f",
                                    }
                                }}><TableCell colSpan={12}><Box sx={{ textAlign: "center", color: '#dbdbeb99', }}>Delivery Not Found</Box></TableCell></TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Box sx={{
                        maxWidth: "400px",
                        width: '100%',
                        marginLeft: 'auto',
                        marginBottom: '30px',
                        marginTop: '50px'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            marginBottom: ''
                        }}>
                            <Box sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                                {packages?.length > 0 && <CustomButton buttonName="Add Other Fees" color="primary" onClick={() => setIsOtherFeesOpen(true)} />}
                                {packages?.length > 0 && <CustomButton buttonName="Get Quote" color="primary" onClick={handleGetQuote} disabled={isQuoteLoading} isLoading={isQuoteLoading} />}
                            </Box>
                        </Box>
                        {quoteData?.data?.distribution[0] && <TableContainer sx={{
                            backgroundColor: 'transparent'
                        }}>
                            <Typography sx={{ marginTop: "20px", textAlign: "center" }}>Current plan - {subscription_type}</Typography>

                            <Table aria-label="simple table" sx={{
                                border: '1px solid #dbdbeb1f'
                            }}>

                                <TableBody>
                                    <TableRow
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& td,th': {
                                                color: '#dbdbeb99',
                                                borderBottomColor: "#dbdbeb1f",
                                            }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Name
                                        </TableCell>
                                        <TableCell align="center">Value</TableCell>
                                    </TableRow>
                                    {!is_fred_pilot &&
                                        <>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    '& td,th': {
                                                        color: '#dbdbeb99',
                                                        borderBottomColor: "#dbdbeb1f",
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Charge
                                                </TableCell>
                                                <TableCell align="center">
                                                    {quote?.data?.distribution[0]?.surcharge_amount !== undefined && packages?.length > 0
                                                        ? `AUD ${(quote.data.distribution[0].surcharge_amount / 100).toFixed(2)}`
                                                        : "AUD 0.00"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    '& td,th': {
                                                        color: '#dbdbeb99',
                                                        borderBottomColor: "#dbdbeb1f",
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    GST
                                                </TableCell>
                                                <TableCell align="center">
                                                    {quote?.data?.distribution[0]?.gst_amount !== undefined && packages?.length > 0
                                                        ? `AUD ${(quote.data.distribution[0].gst_amount / 100).toFixed(2)}`
                                                        : "AUD 0.00"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    '& td,th': {
                                                        color: '#dbdbeb99',
                                                        borderBottomColor: "#dbdbeb1f",
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Other Fees Amount
                                                </TableCell>
                                                <TableCell align="center">
                                                    {quote?.data?.distribution[0]?.other_fees_amount !== undefined && packages?.length > 0
                                                        ? `AUD ${(quote.data.distribution[0].other_fees_amount / 100).toFixed(2)}`
                                                        : "AUD 0.00"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    '& td,th': {
                                                        color: '#dbdbeb99',
                                                        borderBottomColor: "#dbdbeb1f",
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Total Without GST
                                                </TableCell>
                                                <TableCell align="center">
                                                    {TotalAmountWithoutGST !== undefined && !isNaN(TotalAmountWithoutGST) && packages?.length > 0 && quote?.data?.package_quotes?.length
                                                        ? `AUD ${(TotalAmountWithoutGST / 100).toFixed(2)}`
                                                        : "AUD 0.00"}
                                                </TableCell>

                                            </TableRow>
                                        </>}
                                    {is_fred_pilot && <TableRow
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& td,th': {
                                                color: '#dbdbeb99',
                                                borderBottomColor: "#dbdbeb1f",
                                            }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Fred Pilot Delivery
                                        </TableCell>
                                        <TableCell align="center">
                                            AUD 0.00
                                        </TableCell>
                                    </TableRow>}
                                    <TableRow
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& td,th': {
                                                color: '#dbdbeb99',
                                                borderBottomColor: "#dbdbeb1f",
                                            }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Total({`${quote?.data?.package_quotes?.length !== undefined ? quote.data.package_quotes.length : '0'} Delivery`})
                                        </TableCell>
                                        <TableCell align="center">
                                            {quote?.data?.distribution[0]?.total_charge_amount !== undefined && packages?.length > 0
                                                ? `AUD ${final_total}`
                                                : "AUD 0.00"}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                gap: "10px",
                                justifyContent: 'start',
                                flexWrap: 'wrap',
                            }}>
                                <CustomButton buttonName="Import CSV" onClick={handleImportCSV} color="primary" />
                                <CustomButton buttonName="Download CSV Template" onClick={handleDownloadCSVTemplate}
                                    disabled={isCsvLoading} isLoading={isCsvLoading} color="primary" />
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                gap: "10px",
                                justifyContent: { xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'start' },
                            }}>
                                {quote?.data?.package_quotes?.length && <CustomButton
                                    onClick={handlePaymentClicked}
                                    buttonName="Proceed To Pay " color="primary" />}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <CustomModal maxWidth="md"
                    title="Add Deliveries"
                    isModalOpen={showAddPackageDialog}
                    isShowActionButton={false}
                    handleClose={() => setShowAddPackageDialog(false)}
                >
                    <CreatePackage maxWidth="md" resetOrderID={() => {
                        // setVariableOrderID(0);
                    }}
                        handleGetQuote={handleGetQuote}
                        orderStatus={orderStatus}
                        addressBooksData={addressBooksData}
                        orderID={orderID} setQuoteData={setQuoteData}
                        setShowAddPackageDialog={setShowAddPackageDialog}
                        isPackageEditing={isPackageEditing}
                        setIsPackageEditing={setIsPackageEditing}
                        selectedPackage={selectedPackage ? selectedPackage : null}
                        refetchMyOrder={refetchMyOrder}
                        refetchOrder={refetchOrder} setShowPayButton={setShowPayButton}
                        setIsMenuOpen={setIsMenuOpen}
                    />
                </CustomModal>

                <CustomModal isShowActionButton={false} title="Add Other Fees" isModalOpen={isOtherFeesOpen} handleClose={() => setIsOtherFeesOpen(!isOtherFeesOpen)}>
                    <AddOtherFees payOtherFeesLoading={payOtherFeesLoading} handleAddOtherFees={handleAddOtherFees} handleClose={() => setIsOtherFeesOpen(!isOtherFeesOpen)} />
                </CustomModal>

                {isErrorFredModalOpen && <ErrorPopup errorMessageFred={errorMessageFred} handleClose={handleErrorFredPopupClose} isErrorFredModalOpen={isErrorFredModalOpen} />}
            </CustomPaperBox>
        </>
    )
}

export default OrderPackages;
