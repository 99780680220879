import { Box, Typography } from '@mui/material';
import React from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import moment from 'moment';

const StatusForUser = ({ branger, dropETA }) => {
    const task_status = branger?.package?.[0]?.task_status;
    const delivery_started_time = branger?.package?.[0]?.delivery_started_at ? moment.utc(branger?.package?.[0]?.delivery_started_at).format("HH:mm") : null;

    return (
        <>
            <Box className="delivery_title">
                <Typography>Delivery Status:</Typography>
            </Box>
            <Box className="timeline-item">
                <Box className={`timeline-dot ${task_status === "DELIVERED" ? "active_status" : ""} success`}><LocationOnIcon sx={{ fontSize: "18px" }} /></Box>
                <Box className="timeline-content">
                    <Box className="status">Delivered successfully</Box>
                    {/* {task_status !== "DELIVERED" && dropETA && <Box className="estimate-card"> ETA: {dropETA} </Box>} */}
                </Box>
            </Box>
            <Box className="timeline-item">
                <Box className={`timeline-dot ${delivery_started_time ? task_status === "PICKEDUP" ? "active_status" : "" : ""}`}><LocalShippingOutlinedIcon sx={{ fontSize: "18px" }} /></Box>
                <Box className="timeline-content">
                    <Box className="status">On the way {dropETA && <Typography variant="span" className='timeline_eta'>({dropETA})</Typography>}</Box>
                </Box>
            </Box>
            <Box className="timeline-item">
                <Box className={`timeline-dot ${task_status === "PICKEDUP" ? !delivery_started_time ? "active_status" : "" : ""}`}><ShoppingBagOutlinedIcon sx={{ fontSize: "18px" }} /></Box>
                <Box className="timeline-content">
                    <Box className="status">Package picked up</Box>
                </Box>
            </Box>
            <Box className="timeline-item">
                <Box className={`timeline-dot ${task_status === "STARTED" ? "active_status" : ""}`}><PermIdentityOutlinedIcon sx={{ fontSize: "18px" }} /></Box>
                <Box className="timeline-content">
                    <Box className="status">Driver assigned</Box>
                </Box>
            </Box>
        </>
    )
}

export default StatusForUser