import { Chip } from '@mui/material';
import React from 'react'

const CustomChips = ({ label, color, labelColor, textTransform="uppercase", sx }) => {

    const bgMappings = {
        primary: "#696cff29 !important",
        secondary: "#8592a329 !important",
        success: '#71dd3729 !important',
        error: "#ff3e1d29 !important",
        warning: "#ffab0029 !important",
        info: "#03c3ec29 !important",
    };

    const colorMappings = {
        primary: "#696cff !important",
        secondary: "#8592a3 !important",
        success: '#67c932 !important',
        error: "#ff3e1d !important",
        warning: "#ffab00 !important",
        info: "#03c3ec !important",

    };

    return (
        <Chip
            sx={{...sx,
                backgroundColor: bgMappings[color] || "",
                padding: '7px 0px',
                borderRadius: '4px',
                marginBottom: "5px",
                '& span': {
                    textTransform: textTransform,
                    padding: '7px 10px',
                    color: colorMappings[labelColor] || "",
                }
            }}
            label={label} />
    )
}

export default CustomChips
