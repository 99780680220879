import React, { useEffect, useState } from 'react'
import { useCreateAddressBookMutation, useLazyGetSingleAddressBookQuery, useUpdateAddressBookMutation } from '../../store/address-book/addressBookApiSlice';
import { toast } from 'react-toastify';
import { Box, Grid, InputAdornment } from '@mui/material';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import CustomInput from '../../theme/style-components/CustomInput';
import CustomButton from '../../theme/style-components/CustomButton';
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidNumber } from 'libphonenumber-js';
import { showError } from '../../shared/helperFunction';
import * as EmailValidator from 'email-validator';

const CreateAddressBook = (props) => {
    const { ID, isEdit, handleClose, refetchAddressBook } = props;
    const [error, setError] = useState(null);
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [placeID, setPlaceID] = useState("");
    const [note, setNote] = useState("");
    const [autocompleteAddress, setAutocompleteAddress] = useState("");

    const [
        getSingleAddressBook,
        {
            isLoading: isLoading,
            data: dataReturned,
            isSuccess: isSingleAddressBookSuccess,
            isError: isSingleAddressBookError,
            error: singleAddressBookError,
        },
    ] = useLazyGetSingleAddressBookQuery();

    const [createAddressBook, { isLoading: isCreateLoading }] = useCreateAddressBookMutation();
    const [updateAddressBook, { isLoading: isUpdateLoading }] = useUpdateAddressBookMutation();

    useEffect(() => {
        const fetchAddressBook = async () => {
            try {
                const response = await getSingleAddressBook(ID).unwrap();
            } catch (err) {
                toast.error(err.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        };

        if (isEdit) {
            fetchAddressBook();
        }
    }, [isEdit]);

    useEffect(() => {
        if (!isLoading && dataReturned) {
            setAddress(dataReturned.data[0].drop_address.address);
            setCity(dataReturned.data[0].drop_address.city);
            setPostcode(dataReturned.data[0].drop_address.postcode);
            setNote(dataReturned.data[0].drop_address.note);
            setLatitude(dataReturned.data[0].drop_address.lat);
            setLongitude(dataReturned.data[0].drop_address.long);
            setPlaceID(dataReturned.data[0].drop_address.place_id);
            setCountry(dataReturned.data[0].drop_address.country);
            setContactName(dataReturned.data[0]?.contact_name)
            setContactEmail(dataReturned.data[0]?.contact_email)
            setContactPhone(dataReturned.data[0]?.contact_phone)
        }
    }, [isLoading, dataReturned, isEdit])

    const searchOptions = {
        componentRestrictions: { country: "au" }
    };

    const validate = () => {
        let isInvalid = false;
        let errorNew = {};

        if (address.trim().length === 0) {
            errorNew.address = {
                isError: true,
                text: "Address cannot be empty",
            };

            isInvalid = true;
        };

        if (contactName.trim().length === 0) {
            errorNew.contactName = {
                isError: true,
                text: "Contact name cannot be empty",
            };

            isInvalid = true;
        };

        if (contactEmail.trim().length === 0) {
            errorNew.contactEmail = {
                isError: true,
                text: "Contact email cannot be empty",
            };

            isInvalid = true;
        }

        if (!EmailValidator.validate(contactEmail)) {
            errorNew.contactEmail = {
                isError: true,
                text: "Please enter valid email",
            };

            isInvalid = true;
        }

        // Check if the phone number is valid
        const phoneNumberObj = isValidNumber(contactPhone);
        if (contactPhone.trim().length === 0) {
            errorNew.contactPhone = {
                isError: true,
                text: "Contact phone can not be empty",
            };

            isInvalid = true;
        } else if (!phoneNumberObj) {
            errorNew.contactPhone = {
                isError: true,
                text: "Invalid phone number",
            };
            isInvalid = true;
        }


        if (city.trim().length === 0) {
            errorNew.city = {
                isError: true,
                text: "City cannot be empty",
            };

            isInvalid = true;
        }

        if (country.trim().length === 0) {
            errorNew.country = {
                isError: true,
                text: "Country cannot be empty",
            };

            isInvalid = true;
        }

        if (String(postcode).trim().length === 0) {
            errorNew.postcode = {
                isError: true,
                text: "Postcode cannot be empty",
            };

            isInvalid = true;
        }

        if (isInvalid) {
            setError(errorNew);
            return true;
        } else {
            return false;
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            let createData = {
                drop_address: {
                    address: address,
                    note: note,
                    city: city,
                    country: country,
                    postcode: postcode,
                    lat: latitude,
                    long: longitude,
                    place_id: placeID,
                },
                contact_name: contactName,
                contact_email: contactEmail,
                contact_phone: contactPhone,
            };

            let updateData = {
                book_address_id: ID,
                contact_name: contactName,
                contact_email: contactEmail,
                contact_phone: contactPhone,
            };
            if (isEdit) {
                try {
                    const res = await updateAddressBook(updateData).unwrap();
                    if (res) {
                        toast.success(`Address book updated successfully`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        refetchAddressBook();
                        handleClose();
                    }
                } catch (err) {
                    showError(err?.data)
                }
            } else {
                try {
                    const res = await createAddressBook(createData).unwrap();
                    if (res) {
                        toast.success(`Address book created successfully`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        refetchAddressBook();
                        handleClose();
                    }
                } catch (err) {
                    showError(err?.data)
                }
            }
        }
    };

    const handleAddressSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let index = 0;
        const addressLength = results[0].address_components.length;
        let postCode = {};
        let cityObject = {};
        let countryObject = {};
        setAutocompleteAddress(value);
        setAddress(value);
        let stateExists = false;
        const myFunction = (item, index, array) => {
            if (array[index].types[0] == "postal_code") {
                postCode = item;
            }
            if (array[index].types[0] == "country") {
                setCountry(item.long_name);
            }
            if (array[index].types[0] == "administrative_area_level_2") {
                stateExists = true;
            }

            if (!stateExists) {
                if (array[index].types[0] == "administrative_area_level_1") {
                    cityObject = item;
                }
            } else {
                if (array[index].types[0] == "administrative_area_level_2") {
                    cityObject = item;
                }
            }
        };
        results[0].address_components.forEach(myFunction);
        setPostcode(postCode.long_name);
        setCity(cityObject.long_name);
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
        setPlaceID(results[0].place_id);
    };

    return (
        <>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    value={contactName}
                    label="Contact Name"
                    id="standard-adornment-amount"
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    margin="normal"
                    error={error != null && error.contactName && error.contactName.isError}
                    helperText={
                        error != null &&
                        error.contactName &&
                        error.contactName.isError &&
                        error.contactName.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setContactName(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    value={contactEmail}
                    label="Contact Email"
                    type="email"
                    id="standard-adornment-amount"
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    margin="normal"
                    error={error != null && error.contactEmail && error.contactEmail.isError}
                    helperText={
                        error != null &&
                        error.contactEmail &&
                        error.contactEmail.isError &&
                        error.contactEmail.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setContactEmail(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <MuiPhoneNumber sx={{
                    '& .MuiInput-root': {
                        border: '1px solid #dbdbeb61',
                        borderRadius: '3px',
                        padding: '10px',
                        margin: '0',
                        '&::after': {
                            display: 'none',
                        },
                        '&::before': {
                            display: 'none',
                        },

                    },
                    '& .Mui-error': {
                        borderColor: '#d32f2f',
                    },
                    '& .MuiInputLabel-root': {
                        position: 'absolute',
                        top: '-7px',
                        left: '7px',
                        background: '#2b2c40',
                        zIndex: '1',
                        width: 'fit-content',
                        padding: '0 5px',
                    },
                    "& input": {
                        color: "#dbdbebde",
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                        },
                        '&[type=number]': {
                            '-moz-appearance': 'textfield',
                        },
                    },
                    "& .Mui-disabled": {
                        WebkitTextFillColor: "#dbdbeb61 !important",
                        '& fieldset': {
                            borderColor: '#dbdbeb61 !important',
                        },
                    },
                    '& fieldset': {
                        borderColor: '#dbdbeb61'
                    },
                    '& .MuiInputBase-root,label': {
                        color: '#dbdbeb61'
                    },
                    '& svg': {
                        fill: '#dbdbeb61'
                    },
                    '& .MuiMenu-list': {
                        backgroundColor: 'rgb(43, 44, 64) !important'
                    },
                    "& fieldset:hover": {
                        borderColor: "white !important"
                    },
                    '& .MuiOutlinedInput-root': {
                        '&:hover': {
                            '& fieldset': {
                                borderColor: '#dbdbeb61',
                            },
                        },
                    },
                    '& .Mui-focused': {
                        borderWidth: '2px',
                        borderColor: '#696cff',
                    },
                    '& .MuiFormLabel-root': {
                        '&.Mui-focused': {
                            color: 'rgb(105, 108, 255) !important',
                        },
                    },

                }} label="Mobile Number"
                    value={contactPhone}
                    onChange={(value) => {
                        setError(null);
                        setContactPhone(value);
                    }}
                    defaultCountry="au"
                    disableAreaCodes={true}
                    inputProps={{
                        name: 'Mobile Number',
                        required: true,
                    }}
                    error={error != null && error.contactPhone && error.contactPhone.isError}
                    helperText={
                        error != null &&
                        error.contactPhone &&
                        error.contactPhone.isError &&
                        error.contactPhone.text
                    }
                    fullWidth
                />
            </Grid>

            {!isEdit && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <PlacesAutocomplete
                    value={autocompleteAddress}
                    onChange={(e) => {
                        setAutocompleteAddress(e);
                    }}
                    searchOptions={searchOptions}
                    onSelect={(e) => handleAddressSelect(e)}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <Box>
                            <CustomInput

                                fullWidth
                                label="Search Address"
                                variant="outlined"
                                id="standard-adornment-amount"
                                startAdornment={
                                    <InputAdornment position="start"></InputAdornment>
                                }
                                {...getInputProps({ placeholder: "" })}
                            ></CustomInput>
                            <Box
                                style={
                                    suggestions.length > 0
                                        ? {
                                            position: "absolute",
                                            zIndex: 9999,
                                            backgroundColor: "#2b2c40",
                                            marginRight: "15px",
                                            padding: "15px",
                                            border: "1px solid #2b2c40",
                                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px 4px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                                            minWidth: "200px",
                                        }
                                        : { display: "none" }
                                }
                            >
                                {loading ? <div>...loading</div> : null}

                                {suggestions.map((suggestion, index) => {
                                    const style = {
                                        backgroundColor: suggestion.active
                                            ? "#dbdbeb0a"
                                            : "#2b2c40",
                                        padding: "10px 10px",
                                    };

                                    return (
                                        <Box key={index}
                                            {...getSuggestionItemProps(suggestion, { style })}
                                        >
                                            {suggestion.description}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </PlacesAutocomplete>
            </Grid>}

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    multiline
                    maxRows={4}
                    value={address}
                    label="Address"
                    disabled={isEdit}
                    id="standard-adornment-amount"
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    margin="normal"
                    error={error != null && error.address && error.address.isError}
                    helperText={
                        error != null &&
                        error.address &&
                        error.address.isError &&
                        error.address.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setAddress(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    name="City"
                    label="City"
                    value={city}
                    disabled={isEdit}
                    id="standard-adornment-amount"
                    error={error != null && error.city && error.city.isError}
                    helperText={
                        error != null &&
                        error.city &&
                        error.city.isError &&
                        error.city.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setPostcode(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    name="Postcode"
                    label="Postcode"
                    type="number"
                    value={postcode}
                    disabled={isEdit}
                    id="standard-adornment-amount"
                    margin="normal"
                    error={
                        error != null && error.postcode && error.postcode.isError
                    }
                    helperText={
                        error != null &&
                        error.postcode &&
                        error.postcode.isError &&
                        error.postcode.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setPostcode(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    name="note"
                    label="Note"
                    type="text"
                    value={note}
                    id="standard-adornment-amount"
                    margin="normal"
                    onChange={(e) => {
                        setError(null);
                        setNote(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} gap={2} md={12} >
                <Box sx={{ justifyContent: "end", display: "flex", marginTop: "20px", gap: "10px" }}>
                    <CustomButton
                        onClick={handleSubmit}
                        type="submit"
                        color="primary"
                        buttonName="Submit"
                        isLoading={isCreateLoading || isUpdateLoading}
                        disabled={isCreateLoading || isUpdateLoading}
                    />
                    <CustomButton buttonName="Cancel" color="secondary" onClick={handleClose} />
                </Box >
            </Grid>
        </>
    )
}

export default CreateAddressBook
