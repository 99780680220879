import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetBrangersQuery } from "../../store/brangers/brangerApiSlice";
import CustomTable from "../../theme/style-components/CustomTable";
import moment from "moment";
import { Avatar, Box, Grid, MenuItem, Typography } from '@mui/material';
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import CustomInput from '../../theme/style-components/CustomInput';
import CustomSelect from '../../theme/style-components/CustomSelect';
import { selectBrangers, setBrangers } from '../../store/brangers/brangerSlice';
import { useDispatch, useSelector } from 'react-redux';
import LottieLoader from '../loader/LottieLoader';
import ComponentTitle from '../../shared/ComponentTitle';
import TabTitle from '../../shared/TabTitle';


const Brangers = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [query, setQuery] = useState("");
    const [searchText, setSearchText] = useState("")
    const [idFilter, setIdFilter] = useState("");
    const [count, setCount] = useState();
    const [seletedBranger, setSelectedBranger] = useState({});
    const [isUserVerifiedFilter, setIsUserVerifiedFilter] = useState(null);
    const [isUserActiveFilter, setIsUserActiveFilter] = useState(null);
    const [brangersToDisplay, setBrangersToDisplay] = useState();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const brangers = useSelector(selectBrangers);

    const {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error
    } = useGetBrangersQuery({ page: page, query: query });

    //hooks
    useEffect(() => {
        if (!isLoading) {
            dispatch(setBrangers(dataReturned.data));
            setCount(dataReturned?.page_details?.count);
        }
    }, [isLoading, page, dataReturned?.data]);

    //hooks
    useEffect(() => {
        if (brangers) {
            setBrangersToDisplay(brangers);
        }
    }, [brangers]);

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'image', label: 'Image' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'device_type', label: 'Device Type' },
        { id: 'device_version', label: 'Device Version' },
        { id: 'team', label: 'Team' },
        { id: 'tags', label: 'Tags' },
        { id: 'rating', label: 'Rating' },
        { id: 'role_name', label: 'Role Name' },
        { id: 'status', label: 'Status' },
        { id: 'registration_date', label: 'Registration Date' },
        { id: 'licence_expiry_date', label: 'Licence Expiry Date' },
        { id: 'action', label: 'Action' },
    ];

    const brangerData = brangersToDisplay?.length > 0 ? brangersToDisplay.map((data) => ({
        id: data.id,
        image: <Box>
            <Avatar alt={data.dp} src={data?.dp} />
        </Box>,
        name: <p>{data.first_name} {data.last_name}</p>,
        email: data.email,
        device_type: "N/A",
        device_version: "N/A",
        team: "N/A",
        tags: "N/A",
        rating: "N/A",
        role_name: "N/A",
        status: data?.branger_details?.branger_status,
        registration_date: moment.utc(data?.branger_details?.created_at).format('DD MMMM YYYY'),
        licence_expiry_date: moment.utc(data?.branger_details?.license_expiry).format('DD MMMM YYYY'),
        action: "ACTION"
    })) : []

    const handleCreateClick = () => {
        navigate("/brangers/create")
    };

    const handleEditClick = () => {
        navigate(`/brangers/edit/${seletedBranger.id}`)
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&user_search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&user_search=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };

    const handleIdSearch = (e) => {
        const newSearchId = e.target.value;

        setIdFilter(newSearchId);
        let newQuery = query;

        // Remove existing id parameter from the query
        newQuery = newQuery.replace(/&id=[^&]*/g, '');

        // Add new id parameter if ID is not empty
        if (newSearchId !== '') {
            newQuery += `&id=${encodeURIComponent(newSearchId)}`;
        }

        setQuery(newQuery);
    };

    const handleVerifieChange = (e) => {
        setIsUserVerifiedFilter(e.target.value);
        if (e.target.value !== null) {
            setPage(1);
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=false`, "")
            );
            setQuery(
                (prevQuery) =>
                    prevQuery + `&user_verified=${e.target.value}`
            );
        }

        if (e.target.value === null) {
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=false`, "")
            );
        }
    }

    const handleActiveChange = (e) => {
        setIsUserActiveFilter(e.target.value);
        if (e.target.value !== null) {
            setPage(1);
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=false`, "")
            );
            setQuery(
                (prevQuery) =>
                    prevQuery + `&user_active=${e.target.value}`
            );
        }
        if (e.target.value === null) {
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=false`, "")
            );
        }
    };


    const setSelectedData = (item) => {
        setSelectedBranger(item)
    };
    const breadcrumbs = [
        <Typography key="1" variant="body1">
            Brangers
        </Typography>,
    ];


    return (
        <Box>
            <TabTitle title="Brangers" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <CustomPaperBox>
                <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomInput
                                placeholder="Search by id"
                                type="search"
                                value={idFilter}
                                onChange={handleIdSearch}
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomSelect
                                value={isUserVerifiedFilter}
                                label="Status"
                                onChange={handleVerifieChange}
                            >
                                <MenuItem value={null}>None</MenuItem>
                                <MenuItem value={true}>Verified</MenuItem>
                                <MenuItem value={false}>Not Verified</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomSelect
                                value={isUserActiveFilter}
                                label="Status"
                                onChange={handleActiveChange}
                            >
                                <MenuItem value={null}>None</MenuItem>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                </Grid>
            </CustomPaperBox>
            {brangersToDisplay && count !== undefined ? (
                <>
                    <CustomTable
                        //pagination props
                        title="Branger"
                        page={page}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}

                        //create button props
                        handleCreateClick={handleCreateClick}
                        createButtonName="Create Branger"

                        //edit menu props
                        isShowEditMenu={true}
                        handleEditClick={handleEditClick}

                        //chat menu props
                        isShowChatMenu={true}
                        handleChatClick={handleEditClick}

                        //action props
                        isActionVisible={true}

                        //collapse props
                        isCollapse={false}

                        //data props
                        data={brangerData}
                        columns={columns}

                        //search props
                        isShowSearch={true}
                        handleOnSearch={handleOnSearch}
                        searchText={searchText}
                        searchPlaceholder="Search Name"

                        //export button
                        isShowExportCSV={false}

                        //set Selected row data 
                        setSelectedData={setSelectedData}
                    />
                </>
            ) : <Box>
                <LottieLoader />
            </Box>}
        </Box>
    );
}

export default Brangers;