import React from 'react';
import { useFormik } from "formik";
import * as yup from "yup";
import CustomInput from '../../theme/style-components/CustomInput';
import CustomButton from '../../theme/style-components/CustomButton';
import { Box, Grid } from '@mui/material';
import { showError } from '../../shared/helperFunction';

const Surcharge = () => {
    const validationSchema = yup.object({
        surcharge_perc: yup
            .number("Surcharge percentage must be a number")
            .required("Surcharge percentage is required")
            .min(0, "Surcharge percentage cannot be less than 0")
            .max(100, "Surcharge percentage cannot be more than 100"),  // Percentage validation
    });

    const formik = useFormik({
        initialValues: {
            surcharge_perc: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const { surcharge_perc } = values;
            console.log("values");
            try{
                // const response = 
            }catch(err){
                showError(err?.data);
            }
        },
    });

    return (
        <Grid md="12" sx="12" lg="12" item>
                <Box marginTop="10px">
                    <CustomInput
                        type="number"
                        id="surcharge_perc"
                        name="surcharge_perc"
                        label="Surcharge Percentage"
                        value={formik.values.surcharge_perc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        min={0}
                        error={
                            formik.touched.surcharge_perc &&
                            Boolean(formik.errors.surcharge_perc)
                        }
                        helperText={
                            formik.touched.surcharge_perc && formik.errors.surcharge_perc
                        }
                    />
                </Box>
                <Box sx={{ marginTop: "20px", textAlign: "center" }} >
                    <CustomButton
                        buttonName="Submit"
                        isLoading={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                        color="primary"
                    />
                </Box>
        </Grid>
    )
}

export default Surcharge;