import { Box, Button, Typography } from '@mui/material';
import userDefaultImage from '../../assets/default-user.png'
import React from 'react'

const DriverDetails = ({ branger }) => {
    const driver_image = branger?.branger_dp
    const driver_name = `${branger?.branger_first_name ?? ""} ${branger?.branger_last_name ?? ""}`
    const driver_phone = branger?.branger_phone ?? "NA"

    return (
        <Box className="driver_name_section">
            <Box className="delivery_title">
                <Typography>Branger Details:</Typography>
            </Box>
            <Box className="driver_name_section_box">
                <Box className="driver_user_box">
                    <Box className="driver_user_image">
                        <img src={driver_image ? driver_image : userDefaultImage} alt="" />
                    </Box>
                    <Box className="driver_title">
                        <Typography>{driver_name}</Typography>
                    </Box>
                </Box>
                <Box className="call_btn">
                    <Button href={`tel:${driver_phone}`}>Call</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default DriverDetails;